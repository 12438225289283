import React, { useRef, useState } from "react";
import {InputAdornment,TextField,FormControl, IconButton, Button, FormGroup, FormControlLabel, Checkbox} from "@mui/material";
import {AccountCircleOutlined,VisibilityOff,Visibility,LockOutlined } from '@mui/icons-material';
import { Link, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { loginApi } from "../../../services/transferData";
import { errorMessage, succesMessage } from "../../../utils/toastMessage";
import axios from "axios";
import { Helmet } from "react-helmet";

  const Login = () => {
    const [showPassword,setShowPassword] = useState(false);
    const [,setForceUpdate] = useState(false);
    const [loginData, setLoginData] = useState({
        username:"",
        password:""
    });
    const Redirect = useNavigate();
    

      const validator = useRef(new SimpleReactValidator({
        element: (message) => <div className="text-danger text-start p-1">{message}</div>
      }))
    
      const handleChange = e => {
        const {name,value} = e.target
        setLoginData({ ...loginData, [name]: value.trim() });
      };
    
      const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
      };

      const handleSubmitForm = async (event) => {
        event.preventDefault();
        const form_data = new FormData();
        form_data.append('username',loginData.username);
        form_data.append('password',loginData.password);
        try {
            if (validator.current.allValid()) {
                const {status,data} = await loginApi(form_data);
                if(status === 200){
                    succesMessage("Login was successful.")
                    localStorage.setItem('token',data.token);
                    axios.defaults.headers.common['Authorization'] = data.token;
                    Redirect("/dashboard/patient-info");
                }
            }else{
                validator.current.showMessages();
                setForceUpdate(true);
            }
        } catch (error) {
            errorMessage('Login was not successful!');
        }
      };
  
    return (
        <>
            <Helmet>
                <title>login</title>
            </Helmet>
            <div className="login-wrapper">
                <div className="login-container">
                    <div className="login-content">
                        <div className="login-content-logo mb-5">
                            <img src="../assets/images/logo.png" alt=""/>
                        </div>
                        <div className="login-content-form">
                            <h2>Sign In</h2>
                            <form className="text-center">
                                <div className="row">
                                    <div className="col-12">
                                        <FormControl className="mb-2" sx={{width:'100%'}}>
                                            <TextField
                                                type="text"
                                                name="username"
                                                required
                                                autoComplete="username"
                                                // id="input-with-icon-textfield"
                                                label="Username"
                                                value={loginData.username}
                                                onChange={e => {
                                                    handleChange(e);
                                                    validator.current.showMessageFor("username")
                                                }}
                                                InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircleOutlined />
                                                    </InputAdornment>
                                                ),
                                                }}
                                                variant="standard"
                                            />                                   
                                        </FormControl>
                                        {validator.current.message("username",loginData.username,"required")}
                                    </div>
                                    <div className="col-12">
                                        <FormControl className="mt-2" sx={{width: '100%' }} variant="outlined">
                                            <TextField
                                                required
                                                // id="input-with-icon-textfield"
                                                label="Password"
                                                name="password"
                                                autoComplete="current-password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={loginData.password}
                                                onChange={e => {
                                                    handleChange(e);
                                                    validator.current.showMessageFor("password")
                                                }}
                                                InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockOutlined/>
                                                    </InputAdornment>
                                                ),
                                                endAdornment:(
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                    >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                )
                                                }}
                                                variant="standard"
                                            />

                                        </FormControl>
                                            {validator.current.message("password",loginData.password,"required")}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <Button type="submit" variant="contained" className='mt-3' color='success' sx={{width:'180px',borderRadius:'25px'}} onClick={handleSubmitForm}>Sign In</Button>
                                </div>
                            </form>
                            <div className="row mt-5 text-center">
                                <div className="col-6 ">
                                    <FormGroup className="align-content-center">
                                        <FormControlLabel className="text-success " control={<Checkbox color="success"/>} label="Remember Me" />
                                    </FormGroup>
                                </div>
                                <div className="col-6 align-self-center text-primary"><Link to="#">Forgot Password?</Link></div>
                                <div className="col-12 text-center"><Link to="#">Not a member? <span className="text-success">Sign Up</span></Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
  };
  
  export default Login;
  