import React from 'react'
import { Outlet } from 'react-router';
import Sidebar from '../sidebar/Sidebar';

const MainLayout = () => {
    return ( 
        <>
            <div className='layout-wrapper'>
                <div className='layout-container'>
                    <div className='layout-sidebar'>
                        <Sidebar/>
                    </div>
                    <div className='layout-content'>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default MainLayout;