import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { isEmpty } from 'lodash';

const ChartBand = ({state}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'BAND',
      },
    },
  };
  const labels = [''];

  const data = {
    labels,
    datasets: !isEmpty(state) ? Object.keys(state).map(x => ({
      label:x,
      data: [state[x]],
      backgroundColor: x === 'delta' ? '#d635eb80' : x === 'theta' ? '#ff638480' : x === 'alpha' ? '#35a2eb80' : x === 'sigma' ? '#35ebbe80' : x === 'beta' ? '#6cbe8080' : ''
    })) : [{
      // label:'',
      data: [],
    }]
  };
  return ( 
      <Bar data={data} options={options} />
  );
}
 
export default ChartBand;