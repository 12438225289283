import axios from "axios";
import { decode_Token } from "../utils/jwt-decode";
import { errorMessage, warningMessage } from "../utils/toastMessage";

axios.defaults.headers.common["Authorization"] = localStorage.getItem('token') ? localStorage.getItem('token') : null;

axios.interceptors.response.use(null,async error => {
    const exeptedError = error.response && error.response.status >= 400 && error.response.status < 500;
    if (!exeptedError) {
        errorMessage("A problem has occurred on the server side!");
    };
    if (error.response.status === 401) {
        window.location.href = '/';
        localStorage.removeItem('token');
        warningMessage("Please login first!");
        return Promise.reject(error);
    };

    if (error.response.status === 401) {
        const token = localStorage.getItem('token');
        if (token) {
            const decodeToken = decode_Token(token);
            const date_now = Math.ceil(Date.now() / 1000);
            if (decodeToken.exp < date_now) {
                errorMessage("Your login has timed out, please login again.")
                window.location.href = "/";
            }
        }else{
            errorMessage("Please login first!")
            window.location.href = '/';
        }
    };
    return Promise.reject(error);
});

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    post: axios.post,
    get: axios.get,
    delete: axios.delete,
    put: axios.put
}