import React, { useContext, useEffect, useRef } from 'react'
import { Button, ButtonGroup, TextField } from '@mui/material';
import _, { isEmpty } from 'lodash';
import ChartStage from '../../chart/ChartStage';
import ChartBand from '../../chart/ChartBand';
import LineChart1 from '../../chart/LineChart1';
import LineChart2 from '../../chart/LineChart2';
import { useLocation } from 'react-router';
import { context } from '../../../context/context';
import { Helmet } from 'react-helmet';

const Home = () => {
    const home_context = useContext(context);
    const ws = useRef(null);

    const {
        channel1,
        setChannel1,
        channel2,
        setChannel2,
        stage,
        setStage,
        band,
        setBand
    } = home_context;

    const location = useLocation();
    const {name,gender,dr} = !isEmpty(location.state) ? location.state : {name:'',gender:'',dr:''}

    const line_name_1 = ['Fp1', 'Fp2', 'F3', 'F4', 'C3', 'C4', 'P3', 'P4', 'O1', 'O2'];
    const line_name_2 = ['F7', 'F8','T3', 'T4', 'T5', 'T6', 'Fz', 'Cz', 'Pz', 'Oz'];

    useEffect(() => {
        ws.current = new WebSocket("ws://185.79.156.226:8888/ws/graph/");
        ws.current.onopen = () => console.log("ws opened");
        ws.current.onclose = () => {
            console.log("ws closed");
            ws.current = null;
        };
        const wsCurrent = ws.current;
        return () => {
            wsCurrent.close();
        };
    }, []);

    const handleStartChart = () => {
            if (ws.current) {
                ws.current.onmessage = (event) => {
                    // if (isPaused) return;
                    let subset1 = _.pick(JSON.parse(event.data),['channel1','channel2','channel3','channel4','channel5','channel6','channel7','channel8','channel8','channel10'])
                    let subset2 = _.pick(JSON.parse(event.data),['channel11','channel12','channel13','channel14','channel15','channel16','channel17','channel18','channel19','channel20'])
                    setChannel1(subset1);
                    setChannel2(subset2);
                    setStage(JSON.parse(event.data).stage);
                    setBand(JSON.parse(event.data).bands);
                };
                ws.current.onerror = () => {
                    console.log('we have a error!!!');
                }
            }else{
                ws.current = new WebSocket("ws://185.79.156.226:8888/ws/graph/");
                ws.current.onmessage = (event) => {
                    let subset1 = _.pick(JSON.parse(event.data),['channel1','channel2','channel3','channel4','channel5','channel6','channel7','channel8','channel8','channel10'])
                    let subset2 = _.pick(JSON.parse(event.data),['channel11','channel12','channel13','channel14','channel15','channel16','channel17','channel18','channel19','channel20'])
                    setChannel1(subset1);
                    setChannel2(subset2);
                    setStage(JSON.parse(event.data).stage);
                    setBand(JSON.parse(event.data).bands);
                };
            };
    };
    

    const handleStopChart = () => {
        ws.current.close();
    };

    return ( 
        <>
            <Helmet>
                <title>dashboard</title>
            </Helmet>
            <div className='home-wrapper'>
                <div className='home-container'>
                    <div className='home-header'>
                        <div className='home-header-content px-3'>
                            <div className='w-100'>
                                <TextField
                                sx={{width:'98%'}}
                                id="outlined-read-only-input"
                                label="Patient Name:"
                                defaultValue={name}
                                InputProps={{
                                    readOnly: true,
                                }}
                                />                            
                            </div>
                            <div className='w-100'>
                                <TextField
                                sx={{width:'98%'}}
                                id="outlined-read-only-input"
                                label="Gender:"
                                defaultValue={gender}
                                InputProps={{
                                    readOnly: true,
                                }}
                                />                            
                            </div>
                            <div className='w-100'>
                                <TextField
                                sx={{width:'98%'}}
                                id="outlined-read-only-input"
                                label="Doctor Name:"
                                defaultValue={dr}
                                InputProps={{
                                    readOnly: true,
                                }}
                                />                            
                            </div>
                            <div className='w-100 text-center'>
                                {/* <TextField
                                sx={{width:'48%',marginX:'4px'}}
                                id="outlined-select-currency"
                                select
                                label="Choose channel :"
                                value={10}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                </TextField>
                                <TextField
                                sx={{width:'48%'}}
                                id="outlined-select-currency"
                                select
                                label="Choose channel :"
                                value={10}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                </TextField> */}
                                <ButtonGroup variant="text" aria-label="text button group">
                                    <Button color="success" size="large" onClick={handleStartChart}>Start</Button>
                                    <Button color="success" size="large" onClick={handleStopChart}>Stop</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    <div className='home-content'>
                        <div className='home-content-chart'>
                            <div className='column-chart'>
                                <div className='w-50 mx-3'>
                                    <ChartStage state={stage}/>
                                </div>
                                <div className='w-50 mx-3'>
                                    <ChartBand state={band}/>
                                </div>
                            </div>
                            <div className='line-chart'>
                                {!isEmpty(channel1) ? Object.keys(channel1).map((x,index)=> (
                                        <div className='w-100 mx-3'>
                                            <LineChart1 state={channel1[x]}/>
                                        </div>
                                )) : <LineChart1 state={[]}/>}
                                {!isEmpty(channel2) ? Object.keys(channel2).map((x,index)=> (
                                    <div key={index} className='w-100 mx-3'>
                                        <LineChart2 state={channel2[x]}/>
                                    </div>
                                )) : <LineChart2 state={[]}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Home;