import React from 'react'
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
    return ( 
        <div className='sidebar-wrapper'>
            <div className='sidebar-container'>
                <div className='sidebar-logo'>
                    <img src='../assets/images/logo.png' alt='logo'/>
                </div>
                <div className='sidebar-navbar'>
                    <nav className='navbar'>
                        <ul className='navbar-list'>
                            <li className='nav-item'>
                                <NavLink to="/dashboard"><img src='../assets/images/Home-Vector.png' className='me-2' alt=''/><span>Home</span></NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink to="/dashboard/patient-info"><img src='../assets/images/patient-info-vector.png' className='me-2' alt=''/><span>Patient Info</span></NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink to="/dashboard/patient-register"><img src='../assets/images/patient-reg-vector.png' className='me-2' alt=''/><span>Patient Register</span></NavLink>
                            </li>
                            <li className='nav-item'>
                                <NavLink to="/logout"><img src='../assets/images/logout-vector.png' className='me-2' alt=''/><span>Log Out</span></NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
     );
}
 
export default Sidebar;