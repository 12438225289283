import React, { useState } from 'react'
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { applyPatient } from '../../../services/transferData';
import { errorMessage, succesMessage } from '../../../utils/toastMessage';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';

const PatientRegister = () => {
    const [patient,setPatient] = useState({
        full_name:'',
        phone:'',
        date_of_birth:'',
        gender:'',
        special_desieases:'',
        description:'',
        doctor:''
    });

    const navigate = useNavigate();

    const handleChangeInp = (e) => {
        const {value,name} = e.target;
        setPatient({...patient,[name]: value});
    }

    const handleSubmitPatient = async e =>{
        e.preventDefault();
        const form_data = new FormData();
        form_data.append('full_name',patient.full_name);
        form_data.append('phone',patient.phone);
        form_data.append('date_of_birth',patient.date_of_birth);
        form_data.append('gender',patient.gender);
        form_data.append('special_desieases',patient.special_desieases);
        form_data.append('description',patient.description);
        form_data.append('doctor',patient.doctor);
        try {
            const {status} = await applyPatient(form_data);
            if (status === 200) {
                succesMessage('Patient added successfully!');
                handleReset();
                navigate('/dashboard/patient-info')
            }
        } catch (error) {
            errorMessage('Patient registration was not successful!');
        }
    };

    const handleReset = () => {
        setPatient({
            full_name:'',
            phone:'',
            date_of_birth:'',
            gender:'',
            special_desieases:'',
            description:'',
            doctor:''
        })
    }
    return ( 
        <>
        <Helmet>
            <title>patient register</title>
        </Helmet>
            <div className='patient-reg-wrapper'>
                <div className='patient-reg-container'>
                    <div className='patient-reg-header'>
                        <h2>Patient Registeration</h2>
                    </div>
                    <div className='patient-reg-content'>
                        <form>
                            <div className='row '>
                                <div className='col-md-12 my-1'>
                                    <FormControl sx={{width:'100%'}}>
                                        <TextField id="outlined-basic" label="Name..." variant="outlined" name='full_name' value={patient.full_name} onChange={handleChangeInp}/>
                                    </FormControl>
                                </div>
                                <div className='col-md-12 my-1'>
                                    <FormControl sx={{width:'100%'}}>
                                        <TextField id="outlined-basic" label="Tel."
                                        inputProps={{
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*',
                                            maxLength:'11'
                                        }}
                                         variant="outlined" name='phone' value={patient.phone} onChange={handleChangeInp}/>
                                    </FormControl>
                                </div>
                                <div className='row my-1'>
                                    <div className='col-lg-6 align-self-center'>
                                        <FormControl sx={{width:'100%'}}>
                                            <TextField
                                                id="date"
                                                label="Birthday"
                                                type="date"
                                                defaultValue={patient.date_of_birth}
                                                
                                                InputLabelProps={{
                                                shrink: true,
                                                }}
                                                name='date_of_birth'
                                                onChange={handleChangeInp}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='col-lg-6'>
                                        <FormControl sx={{width:'100%'}}>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={patient.gender}
                                            >
                                                <FormControlLabel value="f" control={<Radio />} label="Female" name='gender' onChange={handleChangeInp}/>
                                                <FormControlLabel value="m" control={<Radio />} label="Male" name='gender' onChange={handleChangeInp}/>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                </div>
                                <div className='col-md-12 my-1'>
                                    <FormControl sx={{width:'100%'}}>
                                        <TextField id="outlined-basic" label="Special dieases" variant="outlined" name='special_desieases' value={patient.special_desieases} onChange={handleChangeInp}/>
                                    </FormControl>
                                </div>
                                <div className='col-md-12 my-1'>
                                    <FormControl sx={{width:'100%'}}>
                                        <TextField id="outlined-basic" label="Description" variant="outlined" name='description' value={patient.description} onChange={handleChangeInp}/>
                                    </FormControl>
                                </div>
                                <div className='col-md-12 my-1'>
                                    <FormControl sx={{width:'100%'}}>
                                        <TextField id="outlined-basic" label="Doctor Name" variant="outlined" name='doctor' value={patient.doctor} onChange={handleChangeInp}/>
                                    </FormControl>
                                </div>
                                <div className='col-md-12 my-1'>
                                    <Button type='submit' variant='contained' sx={{width:'100%',borderRadius:'25px'}} color='success' onClick={handleSubmitPatient}>Submit</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default PatientRegister;