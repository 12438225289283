import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';

const ChartStage = ({state}) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
      );
      console.log(state.data);
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'STAGE',
          },
        },

      };
      const labels = ['Awake','Light','Moderate','Deep'];
    const data = {
      labels,
      datasets: [{
        label: state.label,
        data: labels.map(x => x === state.label ? state.data.map(num => num + 1) : 0),
        backgroundColor: state.backgroundColor
      }]

      
    };
    return ( 
        <Bar data={data} options={options} />
     );
}
 
export default ChartStage;