import React, { useEffect, useState } from 'react';
import { getPatientApi } from '../services/transferData';
import {context} from './context';
const UserContext = ({children}) => {
    const [channel1,setChannel1] = useState([]);
    const [channel2,setChannel2] = useState([]);
    const [stage,setStage] = useState({});
    const [band,setBand] = useState({});
    // ---------------------------------------------------------------
    const [patients,setPatients] = useState([]);




    return ( 
        <context.Provider value={{
            channel1,
            setChannel1,
            channel2,
            setChannel2,
            stage,
            setStage,
            band,
            setBand,
            // -----------------------------------
            patients,
            setPatients
        }}>
            {children}
        </context.Provider>
    );
}
 
export default UserContext;