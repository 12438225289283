import React from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,} from 'chart.js';
import { Line } from 'react-chartjs-2';

const LineChart2 = ({state}) => {
  ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart 1',
      }
    },
    scales:{
      x:{
          grid:{
              drawBorder: false,
              display: false,
          }
      }
    }
  };
  const labels = [];
  for (let x = 0; x < state.length; x++) {
    labels.push("");
  }
  const data = {
    labels,
    datasets: [
      {
        label: 'Data',
        data: state,
        borderColor: 'rgb(53, 162, 235)',
      },
    ],
  };
  return ( 
      <Line options={options} data={data}/>
  );
}
 
export default LineChart2;