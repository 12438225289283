import { createContext } from "react";

export const context = createContext({
    // socket: null,
    channel1: [],
    setChannel1: () => {},
    channel2:[],
    setChannel2:() => {},
    stage:{},
    setStage: () => {},
    band: {},
    setband: () => {},
    // -------------------------------
    search: '',
    setSearch: () => {},
    patients: [],
    setPatients: () => {},
    handleSearch: () => {}
});