import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const LogOut = () => {
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.removeItem('token');
        navigate('/')
    });
}
 
export default LogOut;