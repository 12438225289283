import React, { useRef, useState } from 'react'
import { Button,ButtonGroup,Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx/xlsx.mjs';
import { useReactToPrint } from 'react-to-print';

const PatientTable = ({search,patients}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const componentRef = useRef();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handeExportXlsx = () => {
    let wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(patients);
    XLSX.utils.book_append_sheet(wb,ws,"mySheet1");
    XLSX.writeFile(wb,"patient-information.xlsx");
  }

  const handlePrint = useReactToPrint({
    content:() => componentRef.current,
    documentTitle: 'Patient Information',
  })


  const columns = [
    {id: '0', label: 'Patient Name', minWidth: 150,align: 'center'},
    {id: '1', label: 'Gender', minWidth: 30,align: 'center'},
    {id: '2',label: 'Age',minWidth: 20,align: 'center'},
    {id: '3',label: 'Special disease',minWidth: 150,align: 'center'},
    {id: '4',label: 'Doctor',minWidth: 20,align: 'center'},
    {id: '5',label: 'Description',minWidth: 190,align: 'center'},
    {id: '6',label: 'Result',minWidth: 120,align: 'center'},
  ];

  const filterBySearch = !isEmpty(patients) ? patients.filter(x => x.full_name.toLowerCase().includes(search.toLowerCase())): [];
    return ( 
      <>
        <div className='petient-table-wrapper'>
            <div className='petient-table-container'>
                <div className='petient-table-header'>
                    <Button variant="outlined" className='mx-1 py-2' color='success' sx={{width:'150px',borderRadius:'25px'}} onClick={handeExportXlsx}>Excel</Button>
                    <Button variant="contained" className='mx-1 py-2' color='success' sx={{width:'150px',borderRadius:'25px'}} onClick={handlePrint}>Print</Button>
                </div>
                <div className='patient-table-content' >
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: '90%' }}>
                            <Table stickyHeader aria-label="sticky table" ref={componentRef}>
                              <TableHead>
                                  <TableRow>
                                  {columns.map((column) => (
                                      <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ minWidth: column.minWidth }}
                                      >
                                      {column.label}
                                      </TableCell>
                                  ))}
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {filterBySearch.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                                      return (
                                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                          <TableCell key={index+1} align='center'>{row.full_name}</TableCell>
                                          <TableCell key={index+2} align='center'>{row.gender}</TableCell>
                                          <TableCell key={index+3} align='center'>{row.date_of_birth}</TableCell>
                                          <TableCell key={index+4} align='center'>{row.special_desieases}</TableCell>
                                          <TableCell key={index+5} align='center'>{row.doctor}</TableCell>
                                          <TableCell key={index+7} align='center'>{row.description}</TableCell>
                                          <TableCell key={index+8} align='center'>
                                          <ButtonGroup
                                            disableElevation
                                            variant="contained"
                                            orientation="vertical"
                                            color="success"
                                            aria-label="Disabled elevation buttons"
                                          >
                                            <Link to='/dashboard' state={{
                                              name:row.full_name,
                                              gender:row.gender,
                                              dr:row.doctor
                                            }}><Button sx={{borderRadius:'25px',fontSize:'smaller',color:'#fff'}}>Show Result</Button></Link>
                                          </ButtonGroup>
                                          </TableCell>
                                      </TableRow>
                                      );
                                  })}
                              </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={filterBySearch.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>
        </div>        
      </>
    );
}
 
export default PatientTable;