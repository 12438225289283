import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AnastasiaWeb from './AnastasiaWeb';

function App() {
  return (
    <>
      <BrowserRouter>
          <AnastasiaWeb/>
      </BrowserRouter>
        <ToastContainer/>
    </>
  );
}

export default App;
