import config from './config.json';
import http from './httpService';

export const loginApi = data => {
    return http.post(`${config.ansatasia_api}/login/`,data);
};

export const getPatientApi = () => {
    return http.get(`${config.ansatasia_api}/patient/`)
}

export const applyPatient = (data) => {
    return http.post(`${config.ansatasia_api}/patient/`,data)
}