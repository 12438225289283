import React from 'react'
import { Route, Routes } from 'react-router';
import Home from '../components/body/web/Home';
import Login from '../components/body/web/Login';
import LogOut from '../components/body/web/LogOut';
import PatientInfo from '../components/body/web/PatientInfo';
import PatientRegister from '../components/body/web/PatientRegister';
import MainLayout from '../components/layout/MainLayout';
import UserContext from '../context/UserContext';


const AnastasiaWeb = () => {
    return ( 
        <>
            <Routes>
                <Route path='/' index element={<Login/>}/>
                <Route path='/logout' element={<LogOut/>}/>
                <Route path='/dashboard' element={<MainLayout/>}>
                    <Route path='patient-register' element={<PatientRegister/>}/>
                    <Route path='patient-info' element={<UserContext><PatientInfo/></UserContext>}/>
                    <Route index element={<UserContext><Home/></UserContext>}/>
                </Route>
            </Routes>
        </>
     );
}
 
export default AnastasiaWeb;