import React, { useContext, useEffect, useState } from 'react'
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PatientTable from '../../table/PatientTable';
import { context } from '../../../context/context';
import { getPatientApi } from '../../../services/transferData';
import { Helmet } from 'react-helmet';

const PatientInfo = () => {
    const [search,setSearch] = useState("");

    const patients_context = useContext(context);

    const {
        patients,
        setPatients
    } = patients_context;

    
    const handleSearch = e => {
        if (e.target.value !== "") {
            setSearch(e.target.value);
        }else{
            setSearch("");
        }
    };

    useEffect(() => {
        handleGetPatient()
    },[]);
    
    const handleGetPatient = async () =>{
        try {
            const {data,status} = await getPatientApi();
            if (status === 200) {
                setPatients(data);
            }
        } catch (error) {
            
        }
    }
    return (
        <>
            <Helmet>
                <title>patient information</title>
            </Helmet>
            <div className='patient-info-wrapper'>
                <div className='patient-info-container'>
                    <div className='patient-info-header'>
                        <div className='patient-info-header-title'>
                            <h1>Patient Information</h1>
                            <p className='text-muted'>You can see the list of your users here</p>
                        </div>
                        <div className='patient-info-header-search'>
                            <TextField 
                            id="input-with-icon-textfield"
                            label="Search..."
                            variant="outlined"
                            color='success'
                            sx={{borderRadius:'25px'}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={e => handleSearch(e)}
                            />
                        </div>
                    </div>
                    <div className='patient-info-content text-end mt-3'>
                        <PatientTable search={search} patients={patients}/>
                    </div>
                </div>
            </div>
        </>

    );
}
 
export default PatientInfo;